<template>
   <component
      :is="as"
      :class="[col ? `col-${col}` : 'col', sm && `col-sm-${sm}`, md && `col-md-${md}`, lg && `col-lg-${lg}`]"
   >
      <slot />
   </component>
</template>

<script>
export default {
   name: 'AcmCol',
   props: {
      as: {
         type: [String, Object],
         default: 'div',
      },
      col: {
         type: [String, Number],
         default: null,
      },
      sm: {
         type: [String, Number],
         default: null,
      },
      md: {
         type: [String, Number],
         default: null,
      },
      lg: {
         type: [String, Number],
         default: null,
      },
      Border: {
         type: Boolean,
         default: false,
      },
   },
};
</script>

<style lang="scss" scoped></style>
